import {
  ORDER_REQUEST,
  ORDER_INIT_REQUEST,
  ORDER_TICKETS_REQUEST,
  SET_ORDER,
  CLEAR_ORDER,
  ORDER_CANCEL_REQUEST,
  ORDER_SEASONPASS_TICKETS_REQUEST,
  ORDER_SEASONPASS_TICKETS_PH2_REQUEST
} from './types';

export const orderRequest = (cinemaId, sessionId, tickets, email, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId,showProfileUpdateModal) => ({
  type: ORDER_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    tickets,
    email,
    permotionId,
    sessionSeatPlanValue,
    sessionBookingCountData,
    isAboId,
    aboId,
    showProfileUpdateModal
  }
});
export const orderInitRequest = (cinemaId, sessionId, tickets, email, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId, showProfileUpdateModal) => ({
  type: ORDER_INIT_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    tickets,
    email,
    permotionId,
    sessionSeatPlanValue,
    sessionBookingCountData,
    isAboId,
    aboId,
    showProfileUpdateModal
  }
});
export const orderTicketsRequest = (sessionId, tickets, userSessionId) => ({
  type: ORDER_TICKETS_REQUEST,
  payload: {
    sessionId,
    tickets,
    userSessionId
  }
});
export const orderSeasonPassTicketsRequest = (email, userSessionId, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId) => (
  {
    type: ORDER_SEASONPASS_TICKETS_REQUEST,
    payload: {
      email,
      userSessionId,
      sessionId,
      permotionId,
      sessionSeatPlanValue,
      sessionBookingCountData, isAboId, aboId
    }
  });
export const orderSeasonPassTicketsPh2Request = (userSessionId, tickets, sessionId, aboId) => (
  {
    type: ORDER_SEASONPASS_TICKETS_PH2_REQUEST,
    payload: {
      userSessionId,
      tickets,
      sessionId,
      // permotionId,
      // sessionSeatPlanValue,
      // sessionBookingCountData,
      // isAboId,
      aboId
    }
  });

export const setOrder = order => ({
  type: SET_ORDER,
  payload: {
    order
  }
});
export const clearOrder = () => ({
  type: CLEAR_ORDER
});

export const orderCancelRequest = orderId => ({
  type: ORDER_CANCEL_REQUEST,
  payload: orderId
});
